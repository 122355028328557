import { useSelector } from "react-redux";
import {Modal} from "react-bootstrap";
import { LG } from '../config/Index'

function LoginModal(props) {
    const { gwayz} = useSelector(state=>state.user)
    return (
        <Modal {...props} centered className={'login-modal'}>
            {
                /*<div className={'closeloginbtn'} onClick={props.onHide}>X</div>*/

            }
            <Modal.Body className={'contentmodal py-4'}>
                <div className="text-danger text-center p-3">กรุณาทำรายการจนสำเร็จ ห้ามปิดหน้าต่าง QRCode</div>
            <iframe src={gwayz} width="100%"
          height="450" ></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
