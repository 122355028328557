import {Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import { addCommas, checkcurrency } from '../config/Index'

function LoginModal(props) {
    const { accountdeposit, slipexten, slipphoto } = useSelector(state => state.user)
    const { depositConfirm } = props
    return (
        <Modal {...props} centered className={'login-modal'}>
                <div className={'closeloginbtn'} onClick={props.onHide}>X</div>

            <Modal.Body className={'contentmodal py-4'}>
                <div className="text-center text-danger">สลิปการโอน</div>
                <div className="text-center">
                    
                {
                        slipexten && <img
                        className='rounded me-2 mb-3 mb-md-0'
                        src={`${slipexten},${slipphoto}`}
                        width='280'
                        height='300'
                      ></img>
                      }
                
                </div>
                <div className="text-center text-danger txtboxconfirm">
                    <div>ตรวจสอบการโอนเงินให้ถูกต้อง</div> 
                    <div>ยอดโอนจริงต้องตรงกับ ยอดที่แจ้งฝากเท่านั้น กรณีโอนคนล่ะยอด โปรดรอตรวจสอบ ภายใน 24 ชม</div> 
                    <div>แน่ใจแล้ว กรุณากด "ยืนยันการฝาก" ด่านล่าง ค่ะ</div> 
                </div>
                <div className="text-center">
                <button type="button" onClick={depositConfirm} className="moneyBtn">ยืนยันการฝาก</button>
                </div>
        
           
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
