import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Accordion, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useState} from "react";
import { promotionlist, setPromotion, setBonus } from "../store/user";
import { LG, APIURL } from '../config/Index'

const lgtext = {
    lao:{
      1:'ໂປໂມຊັ້ນ',
      2:'ຮັບເຄດິດ',
      3:'ເມື່ອໂອນແລ້ວກົດປຸ່ມ "ຍືນຢັນຝາກ" ດ້ານລຸ່ມ',
      4:'ຍືນຢັນຝາກ',
    },
    thai:{
      1:'โปรโมชั่น',
      2:'รับเครดิต',
      3:'เมื่อโอนแล้วกรุณากด "ยืนยัน" ด้านล่าง',
      4:'ยืนยันฝาก',
    }
  }
function Promotion() {
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(promotionlist({}))
    }, [])

    const navigate = useNavigate()

    const savepro = (id) => {
        dispatch(setBonus(id))
        navigate('/deposit')
    }

    const {  bonusitems } = useSelector(state=>state.user)
   
    /*
    const [banners] = useState([
        {name: 'banner1', image: './images/promotion/pro0.png'},
        {name: 'banner2', image: './images/promotion/pro1.png'},
        {name: 'banner3', image: './images/promotion/pro2.png'},
        {name: 'banner4', image: './images/promotion/pro3.png'},
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];
    */

    const [banners] = useState([
        //{name: 'banner1', image: './images/banner/01.jpg'},
       // {name: 'banner2', image: './images/banner/02.jpg'},
       // {name: 'banner3', image: './images/banner/03.jpg'},
        {name: 'banner4', image: './images/banner/04.jpg'},
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];

    return (
        <div>
            <div className="headsecion">
                <i className="far fa-gift"/> {lgtext[LG]['1']}
            </div>

            <div className="ctpersonal promotion">
                <div className="gridingame full">

                    {bonusitems && bonusitems.map(banner => {
                        return <div className="ingridgame " key={banner.name}>
                            <div className="iningridgame pro">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className={'p-0 m-0'}>
                                            <img className="accordion" src={`${APIURL}/images/${banner.photo}`}
                                                 alt={banner.name}/>
                                        </Accordion.Header>
                                        {
                                             banner.id == banner.id && <Accordion.Body>
                                                <div className="text-center pt-2">‼️ โปรโมชั่น {banner.name}</div>

                                                <div className=" pt-2"> ✅ เงื่อนไขรับโปรโมชั่น ✅ </div>
                                             
                                             ‼️ ฝากขั่นต่ำ {banner.pmin} <br />
                                             ‼️ จ่ายโบนัสเพิ่ม {banner.pbonus} <br />
                                             ‼️ รับโบนัสสูงสุดไม่เกิน {banner.pmax} <br />
                                             ‼️ เฉพาะ {banner.newmember} <br />
                                             <div className=" pt-2"> ✅ เงื่อนไขการถอน ✅ </div>
                                           
                                             {parseInt(banner.pturntype) === 0 && <span> ‼️  ทำยอดเครดิต {banner.pturnamount}</span> }
                                             {parseInt(banner.pturntype) === 1 && <span> ‼️  ทำยอดเทิร์น {banner.pturn} เท่า</span> }
                                            <br />
                                             ‼️ ถอนได้สูงสุด {banner.pwithdrawamount === 0 ? 'ไม่จำกัด' : banner.pwithdrawamount} <br />
                                             <div className="text-center pt-2"><button type="button" onClick={()=>savepro(banner.id, banner.pbonus)} className="btnLogin">รับโปรโมชั่น</button></div>
                                        </Accordion.Body>
                                        }
                        
                                        
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </div>
    );
}
{
    /*
<h5>คืนยอดเสีย 5% ทุกวันศุกร์</h5>
➭ รับโบนัสคืนยอดเสียรวม สัปดาห์ (ตัดรอบ วันศุกร์ 11:00 ถึง 10:59
ของวันศุกร์อีกสัปดาห์)
<br/>➭ มียอดเสียมากกว่า 1000 บาทต่อสัปดาห์ และต้องมียอดเทิร์นโอเวอร์ 5 เท่า
ของยอดฝาก จึงจะได้รับคืนยอดเสีย 5 %
<br/>➭ รับคืนสูงสุด 5000 บาท
<br/>➭ เมื่อรับโปรโมชั่นเครดิตมีอายุการใช้งาน 1
วันจากนั้นเครดิตคืนยอดเสียจะถูกปรับเป็น 0
<br/>➭ โบนัสจะได้รับทุกวันศุกร์สามารถกดรับได้ที่หน้าเว็บตั้งแต่ 11:30
น. เป็นต้นไป

<button type="button" className="btnLogin my-3">
    <span>รับโปรโมชั่น</span>
</button>
    */
}

export default Promotion;
