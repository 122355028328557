import {useSelector, useDispatch} from "react-redux";
import { useState } from 'react'
import { APIURL, addCommas } from '../config/Index'
import CountdownTimer from './CountdownTimer'
import QRCodeImg from "react-qr-code"
import SlipPhoto from './Showslip'
import ModalSlip from '../components/ModalSlip'
import { LG } from '../config/Index'
import { setslipexten } from '../store/user'
import { CopyToClipboard } from "react-copy-to-clipboard";

const lgtext = {
    lao:{
      1:'ຍອດໂອນ',
      2:'ຮັບເຄດິດ',
      3:'ເມື່ອໂອນແລ້ວກົດປຸ່ມ "ຍືນຢັນຝາກ" ດ້ານລຸ່ມ',
      4:'ຍືນຢັນຝາກ',
    },
    thai:{
      1:'ยอดโอน',
      2:'รับเครดิต',
      3:'เมื่อโอนแล้วกรุณา "แนบสลิป เสร็จแล้ว กดยืนยัน" ด้านล่าง',
      4:'ยืนยันฝาก',
    }
  }
function Profile({depositConfirm, depositReject}) {
    const dispatch = useDispatch()
    const { accountdeposit, slipexten, item } = useSelector(state => state.user)
    const [copied, setcopied] = useState(false);

    const setCopyall = () => {
      setcopied(true);
    };
    
    return (
        <div className='tab-pane fade active show'>
                                         <div className="griddps">
                                          <div>
                                          <CountdownTimer countdownTimestampMs={parseInt(accountdeposit?.mtimestamp)} />
                                          </div>
                                         
                                    <div className="modalspanboxqr mt-3">
                                      {
                                        /*
  <div className="modalspanbox2 pt-2">
                        <span>โอนด้วยบัญชีลงทะเบียนนี้เท่านั้น </span><br/>
                        <span>{item?.bankname}/{item?.accno} </span>
                        
                         
                      
                    </div>
                                        */
                                      }
                                    
                                    <div className="iningriddp21">
                                            <img src={`/images/header.png`} width={300} />
                                            </div>
                                           
                                           
                                            {
                                              /*
 <CopyToClipboard
              text={`${accountdeposit?.accno}`}
              onCopy={() => setCopyall()}
            >
                <button className="btn btnback" >คัดลอกบัญชี</button>
                
                 
                </CopyToClipboard>
                                              */
                                            }
                                           
                    {
                    accountdeposit?.qrcodetype == 1 ?  <QRCodeImg value={accountdeposit?.qrcode} size={250} /> : <img src={`${APIURL}/images/statement/${accountdeposit?.qrcode}`} className="photoqr"/> 
                  }  
                                              
                                               
                                              <div>
                                            <button className='btnc1'>ยอดชำระ : {addCommas(parseFloat(accountdeposit?.amount).toFixed(0))}</button> <br />
                                            </div>
                                           
                                        </div>
                                        </div>
                                       {
                                        /*
                                          <div className="atlconfirm3">ในกรณีท่านเปิดหน้าจอด้วยอุปกรณ์มือถือ สามารถชำระได้ตามขั้นตอนดังนี้ <br />1.บันทึกภาพหน้าจอ<br />2.เปิดแอฟธนาคาร<br />3.เลือกเมนูสแกน QR<br />4.กดปุ่มรูปภาพ เพื่อเลือกรูปหน้าจอ QR ที่จับภาพไว้</div>
                                        */
                                       }
                                        
                                        {
                                          /*
 <div className='text-center pt-1'>
                                        
                                        <div className="modalspanbox2 pt-2">
                        <span>โอนเสร็จแล้ว <br /> กดยืนยันการโอนที่ด้านล่างค่ะ </span>
                        
                          <SlipPhoto depositConfirm={depositConfirm} depositReject={depositReject} />
                      
                    </div>
                  
                   
                                        <div>
                                        
                                        </div>
                                        </div>
                                          */
                                        }
                                       
                                     
           
           <ModalSlip show={slipexten} depositConfirm={depositConfirm} onHide={()=>{dispatch(setslipexten(null))}} />
                                    </div>
    );
}

export default Profile;
